.project {
	font-size: 95%;
	padding: 15px 20px;
}
.project:nth-child(odd) {
	background: var(--project-alt-bg);
}
.project.project-deprecated {
	opacity: .8;
}
.project .project-name {
	font-family: Raleway;
	color: var(--project-name-color);
}
.project .project-description {
	font-size: 80%;
	color: var(--project-description-color)
}
.project .project-stack {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 5px;
}
.project .project-stack .stack-item {
	font-size: 80%;
	margin-left: 0;
}