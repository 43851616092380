.experience {
	font-size: 90%;
}

.job {
	display: flex;
	flex-direction: row;
	margin-bottom: 100px;
}
.job-company {
	width: 20px;
	padding-right: 10px;
	background: var(--job-company-bg);
	margin-right: 10px;
	color: var(--job-company-color);
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
}
.job-company > div {
	text-transform: uppercase;
	font-size: 80%;
	transform: rotate(-90deg);
}
.job-body {
	flex: 2;
}
.role {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	margin-bottom: 5px;
}
.job-details::after {
	width: 1px;
	height: 50px;
	background: var(--role-line-color);
	content: '';
	position: relative;
	margin: 10px 0 20px;
}
.role:last-child .job-details::after {
	display: none;
}

.job-details {
	display: flex;	
	flex-direction: column;
}
.job-stack {
	font-size: 80%;
	display: flex;
	flex-direction: row;
	align-content: flex-start;
	align-items: flex-start;
	justify-content: flex-end;
	flex-wrap:wrap;
	padding: 30px;
	padding-right: 0;
}
.job-detail.company-name {
	display: none;
}
.job-detail.role-time {
	font-size: 75%;
	text-transform: uppercase;
	margin-bottom: 8px;
	color: var(--job-company-color);
}
.job-detail.role-name {
	font-size: 70%;
	text-transform: uppercase;
	margin-bottom: 5px;
}
.job-detail.client-name.current::after {
	background: var(--role-current-bg);
	color: var(--role-current-color);
	padding: 1px 5px;
	font-size: 60%;
	text-transform: uppercase;
	position: relative;
	top: -8px;
	margin-left: 5px;
	border-radius: 3px;
	content: 'Current';
}
.job-detail.client-details {
	font-size: 75%;
	color: var(--role-detail-color);
}

@media screen and (max-width: 890px) {
	.job-details::after {
		display: none;
	}
	.job-stack {
		padding: 0;
		margin: 0 0 25px 0;
		justify-content: flex-start;
	}
}