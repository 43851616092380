.nav.top-nav {
	padding: 10px 20%;
	background: var(--nav-background);
	border-bottom: var(--nav-border-width) solid var(--nav-border-color);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}
.nav.top-nav a {
	margin: 0;
	padding: 0;
	text-decoration: none;
	color: var(--nav-color);
	text-transform: uppercase;
	font-size: 85%;
	padding: 6px 20px;
	border-radius: 4px;
	transition: .2s ease;
	white-space: nowrap;
	border: none;
}
.nav.top-nav a:hover,
.nav.top-nav a:active {
	background: var(--nav-background-hover);
}

@media screen and (max-width: 768px) {
	.nav.top-nav {
		padding: 10px 0;
	}
	.nav.top-nav a {
		padding: 6px 8px;
	}
}